<template>
  <div class="content">
    <!-- Виджет статуса участника -->
    <div class="status-widget" :class="`status-widget--${form.status}`">
      <span>{{ getStatusLabel(form.status) }}</span>
    </div>

    <div class="content__title content__title--party">
      <div class="content__title content__title--party content__title--start">
        <router-link
          :to="{ name: 'journal-list' }"
          class="button button--light button--no-indent button--small"
        >
          <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M7.70917 13.7082C7.61629 13.8013 7.50595 13.8752 7.38447 13.9256C7.263 13.976 7.13277 14.002 7.00125 14.002C6.86973 14.002 6.7395 13.976 6.61802 13.9256C6.49655 13.8752 6.38621 13.8013 6.29332 13.7082L0.293962 7.70884C0.200846 7.61595 0.126968 7.50562 0.0765611 7.38414C0.0261537 7.26266 0.000206594 7.13243 0.000206606 7.00091C0.000206617 6.86939 0.0261537 6.73916 0.0765611 6.61769C0.126969 6.49621 0.200846 6.38587 0.293962 6.29299L6.29333 0.293626C6.48108 0.105873 6.73573 0.00039471 7.00125 0.000394734C7.26677 0.000394757 7.52142 0.105873 7.70918 0.293626C7.89693 0.481379 8.00241 0.736028 8.00241 1.00155C8.00241 1.26707 7.89693 1.52172 7.70917 1.70948L2.41574 7.00091L7.70917 12.2923C7.80229 12.3852 7.87617 12.4956 7.92658 12.617C7.97698 12.7385 8.00293 12.8688 8.00293 13.0003C8.00293 13.1318 7.97698 13.262 7.92658 13.3835C7.87617 13.505 7.80229 13.6153 7.70917 13.7082Z"
                  fill="#2B93E7"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M16 7.00108C16 7.26627 15.8947 7.5206 15.7071 7.70811C15.5196 7.89563 15.2653 8.00098 15.0001 8.00098L2.00149 8.00098C1.7363 8.00098 1.48197 7.89563 1.29445 7.70811C1.10694 7.5206 1.00159 7.26627 1.00159 7.00108C1.00159 6.73589 1.10694 6.48156 1.29445 6.29405C1.48197 6.10653 1.7363 6.00119 2.00149 6.00119L15.0001 6.00119C15.2653 6.00119 15.5196 6.10653 15.7071 6.29405C15.8947 6.48157 16 6.73589 16 7.00108Z"
                  fill="#2B93E7"/>
          </svg>
        </router-link>
        <h1 class="title title--big title--theme">Редактирование члена РСМ</h1>
      </div>
      <button v-if="userRole === 'admin'" @click="openSidebar" class="link" type="button">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7 17.0134L11.413 16.9984L21.045 7.45839C21.423 7.08039 21.631 6.57839 21.631 6.04439C21.631 5.51039 21.423 5.00839 21.045 4.63039L19.459 3.04439C18.703 2.28839 17.384 2.29239 16.634 3.04139L7 12.5834L7 17.0134ZM18.045 4.45839L19.634 6.04139L18.037 7.62339L16.451 6.03839L18.045 4.45839ZM9 13.4174L15.03 7.44439L16.616 9.03039L10.587 15.0014L9 15.0064V13.4174Z"
            fill="#2B93E7"/>
          <path
            d="M5 21H19C20.103 21 21 20.103 21 19V10.332L19 12.332V19H8.158C8.132 19 8.105 19.01 8.079 19.01C8.046 19.01 8.013 19.001 7.979 19H5V5H11.847L13.847 3H5C3.897 3 3 3.897 3 5V19C3 20.103 3.897 21 5 21Z"
            fill="#2B93E7"/>
        </svg>
        <span>Управление</span>
      </button>
    </div>
    <div v-if="form.note" class="comment">
      <div class="comment__info">
        {{ form.note }}
      </div>
    </div>
    <form class="form" @submit.prevent>
      <div class="form__content">
        <div class="form__part">
          <!-- Существующие поля -->
          <div class="form-group">
            <label>Региональная организация<sup>*</sup></label>
            <v-select
              v-model="form.region_org"
              :reduce="organization => organization.id"
              :filterable="false"
              :clearable="false"
              :options="region_options"
              :get-option-label="getLabel"
              placeholder="Выберите региональную организацию"
              class="select"
              :class="{ 'select--error': $v.form.region_org.$error }"
              readonly
            >
              <template slot="open-indicator">
                <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                     fill="none">
                  <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                        stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </svg>
              </template>
              <template slot="no-options">Введите свой запрос для поиска</template>
              <template slot="option" slot-scope="option">
                <div class="select__item d-center">
                  {{ option.name }} (ID: {{ option.id }})
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{ option.name }} (ID: {{ option.id }})
                </div>
              </template>
            </v-select>
            <div class="form-group__error" v-if="$v.form.region_org.$dirty && !$v.form.region_org.required">
              Обязательное поле
            </div>
          </div>
          <!-- Поле для выбора наград -->
          <div v-if="userRole === 'admin'" class="form-group">
            <label>Награды</label>
            <v-select
              v-model="selectedAward"
              :options="awardOptions"
              :get-option-label="getAwardLabel"
              placeholder="Выберите награду"
              class="select"
            >
              <template slot="option" slot-scope="option">
                <div class="select__item d-center">
                  {{ option.name }} ({{ option.typeName }})
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{ option.name }} ({{ option.typeName }})
                </div>
              </template>
            </v-select>
            <button @click="addSelectedAward" class="button button--small">Добавить награду</button>
          </div>
          <!-- Отображение выбранных наград -->
          <div v-if="userRole === 'admin'" class="form-group">
            <label>Присвоенные награды</label>
            <div class="awards-list">
              <div v-for="(award, index) in form.awards" :key="index" class="award-item">
                <span>{{ award.name }} ({{ award.typeName }})</span>
                <button @click="removeAward(index)" class="award-remove">Удалить</button>
              </div>
            </div>
          </div>
          <!-- Остальные поля формы -->
          <div class="form-group">
            <label for="number">Номер членского билета<sup>*</sup></label>
            <div class="form-control">
              <input
                id="number"
                type="number"
                min="0"
                class="form-control__input"
                name="number"
                placeholder="Введите номер членского билета"
                v-model.number="form.number"
                :readonly="form.is_auto_number"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="lastname">Фамилия<sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.lastname.$error }">
              <input
                id="lastname"
                type="text"
                class="form-control__input"
                name="lastname"
                placeholder="Иванов"
                v-model.trim="form.lastname"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.lastname.$dirty && !$v.form.lastname.required">Обязательное поле</div>
          </div>
          <div class="form-group">
            <label for="firstname">Имя<sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.firstname.$error }">
              <input
                id="firstname"
                type="text"
                class="form-control__input"
                name="firstname"
                placeholder="Иван"
                v-model.trim="form.firstname"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.firstname.$dirty && !$v.form.firstname.required">Обязательное поле</div>
          </div>
          <div class="form-group">
            <label for="patronymic">Отчество</label>
            <div class="form-control">
              <input
                id="patronymic"
                type="text"
                class="form-control__input"
                name="patronymic"
                placeholder="Иванович"
                v-model.trim="form.patronymic"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="dob">Дата рождения<sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.dob.$error || $v.age.$error }">
              <masked-input
                v-model="form.dob"
                name="dob"
                mask="11.11.1111"
                placeholder="дд.мм.гггг"
                class="form-control__input"
                id="dob"
                autocomplete="off"
                @blur.native="onCheckBirth"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.dob.$dirty && !$v.form.dob.required">
              Обязательное поле
            </div>
            <div class="form-group__error" v-if="$v.form.dob.$dirty && !$v.age.minValue">
              Минимальный возраст для вступления: 14 лет
            </div>
            <div class="form-group__error" v-if="$v.form.dob.$dirty && !$v.age.maxValue">
              Максимальный возраст для вступления: {{ form.gender === 'Ж' ? 60 : 65 }} лет
            </div>
          </div>
          <div class="form-group">
            <label>Пол</label>
            <div class="form-group__party">
              <label class="radio">
                <div class="radio__text">Мужской</div>
                <input type="radio" name="gender" v-model="form.gender" value="М" />
                <div class="radio__radiomark"></div>
              </label>
              <label class="radio">
                <div class="radio__text">Женский</div>
                <input type="radio" name="gender" v-model="form.gender" value="Ж" />
                <div class="radio__radiomark"></div>
              </label>
            </div>
          </div>
        </div>
        <div class="form__part">
          <div class="form-group">
            <label>Телефон</label>
            <vue-tel-input
              v-model="phone_value"
              defaultCountry="RU"
              mode="international"
              type="tel"
              class="phonepicker"
              :class="{ 'phonepicker--error': !is_phone_valid }"
              :inputOptions="phone_picker_options"
              validCharactersOnly
            >
              <template slot="arrow-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" fill="none">
                  <path d="M4.96 6.75L.23 1.47a.93.93 0 010-1.22.71.71 0 011.09 0L5.5 4.92 9.68.25a.71.71 0 011.1 0c.3.34.3.88 0 1.22L6.03 6.75A.73.73 0 015.5 7c-.2 0-.4-.08-.54-.25z" fill="#2B93E7"></path>
                </svg>
              </template>
            </vue-tel-input>
            <div class="form-group__error" v-if="!is_phone_valid">Недопустимое значение для номера телефона</div>
          </div>
          <div class="form-group">
            <label for="email">Электронная почта</label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.email.$error }">
              <input
                id="email"
                type="email"
                name="email"
                class="form-control__input"
                placeholder="Введите электронную почту"
                v-model.trim="form.email"
                @change="$v.form.email.$touch()"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.email.$dirty && !$v.form.email.email">
              Введите правильный формат почты
            </div>
          </div>
          <div class="form-group">
            <label for="date_entry">Дата вступления<sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.date_entry.$error }">
              <masked-input
                v-model="form.date_entry"
                name="date_entry"
                mask="11.11.1111"
                placeholder="дд.мм.гггг"
                class="form-control__input"
                id="date_entry"
                autocomplete="off"
                @blur.native="$v.form.date_entry.$touch"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.date_entry.$dirty && !$v.form.date_entry.required">
              Обязательное поле
            </div>
            <div class="form-group__error" v-if="$v.form.date_entry.$dirty && !$v.form.date_entry.maxValue">
              Дата вступления, не может быть больше текущей даты
            </div>
          </div>
          <div class="form-group">
            <label>Домашний адрес</label>
            <v-select
              @input="setValues"
              v-model="address_detail"
              :filterable="false"
              :options="address_options"
              @search="onAddressSearch"
              :get-option-label="getLabel"
              placeholder="Введите домашний адрес"
              class="select"
            >
              <template slot="open-indicator">
                <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                     fill="none">
                  <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                        stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </svg>
              </template>
              <template slot="no-options">Введите домашний адрес</template>
              <template slot="option" slot-scope="option">
                <div class="select__item d-center">{{ option.address }}</div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">{{ option.address }}</div>
              </template>
            </v-select>
          </div>
          <div class="form-group">
            <div class="form-group__place">
              <label class="checkbox">
                <div class="checkbox__text">Оплата членского взноса</div>
                <input type="checkbox" name="is_paid" v-model="form.is_paid" />
                <div class="checkbox__checkmark"></div>
              </label>
            </div>
          </div>
          <div class="form-group">
            <div class="form-group__place">
              <label class="checkbox">
                <div class="checkbox__text">Наличие согласия на обработку персональных данных</div>
                <input type="checkbox" name="has_agr_personal" v-model="form.has_agr_personal" />
                <div class="checkbox__checkmark"></div>
              </label>
            </div>
          </div>
          <div v-if="form.has_agr_parent" class="form-group">
            <div class="form-group__place">
              <label class="checkbox">
                <div class="checkbox__text">Наличие согласия на вступление от родителей</div>
                <input type="checkbox" name="has_agr_parent" v-model="form.has_agr_parent" />
                <div class="checkbox__checkmark"></div>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="holder holder--end">
        <button
          @click="onCheckForm()"
          class="button"
          type="submit"
          :class="{ 'preload': loading }"
          :disabled="$v.form.$error"
        >
          <span>Сохранить изменения</span>
          <img src="@/assets/img/preloader.svg" alt="" class="button__preloader"/>
        </button>
      </div>
    </form>
    <SidebarManage
      :title="'Управление'"
      :class="{'sidebar-manage--full': show_sidebar}"
      @close-sidebar="show_sidebar = !show_sidebar"
    >
      <form>
        <div class="form-group">
          <label for="comment_cancel">Примечание</label>
          <div class="form-control">
            <textarea
              @change="sendNote"
              id="comment_cancel"
              name="comment_cancel"
              class="form-control__textarea"
              v-model.trim="form.note"
              placeholder="Введите примечание"
            >
            </textarea>
          </div>
        </div>
      </form>
      <button
        v-if="form.status === 'warning'"
        @click="onContinue"
        class="button button--stretched button--indent-bottom"
        type="button"
      >
        Продлить учёт
      </button>
      <button
        @click="onRefuse"
        class="button button--stretched button--indent-bottom"
        type="button"
      >
        Прекратить учёт
      </button>
    </SidebarManage>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email, minValue, maxValue } from 'vuelidate/lib/validators'
import SidebarManage from '@/components/SidebarManage'
import MaskedInput from 'vue-masked-input'
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import { debounce } from "lodash"

export default {
  name: 'JournalEdit',
  mixins: [validationMixin],
  components: {
    MaskedInput,
    VueTelInput,
    SidebarManage,
  },
  validations() {
    const form = {
      number: { required },
      lastname: { required },
      firstname: { required },
      dob: { required },
      email: { email },
      date_entry: { 
        required, 
        maxValue: value => new Date(`${value.slice(3, 5)}.${value.slice(0, 2)}.${value.slice(6)}`) <= new Date() 
      },
      region_org: { required },
    }
    const age = this.form.gender === 'Ж' 
      ? { minValue: minValue(14), maxValue: maxValue(60) } 
      : { minValue: minValue(14), maxValue: maxValue(65) }
    return { form, age }
  },
  data() {
    return {
      form: {
        status: 'active', // Значение по умолчанию для демонстрации
        awards: [], // Массив для хранения выбранных наград
      },
      selectedAward: null, // Выбранная награда
      awardTypes: {}, // Типы наград
      awards: [], // Все награды
      phone_value: '',
      is_phone_valid: true,
      phone_picker_options: {
        placeholder: 'Введите номер телефона'
      },
      loading: false,
      region_options: [],
      local_options: [],
      address_detail: null,
      address_options: [],
      show_sidebar: false,
      age: 0
    }
  },
  computed: {
    userRole() {
      return this.$store.state.userRole
    },
    // Генерация списка наград с типами
    awardOptions() {
      return this.awards.map(award => ({
        ...award,
        typeName: this.awardTypes[award.type]?.name || 'Неизвестный тип'
      }))
    }
  },
  mounted() {
    this.fetchForm()
    this.$store.dispatch('regOrg/GET_QUERY')
      .then(response => { this.region_options = response.data })
    // Загрузка данных о наградах
    this.loadAwards()
  },
  methods: {
    getStatusLabel(status) {
      switch (status) {
        case 'active':
          return 'Активный'
        case 'warning':
          return 'Требует внимания'
        case 'disactive':
          return 'Снят с учета'
        default:
          return 'Неизвестный статус'
      }
    },
    fetchForm() {
      this.$store.dispatch('journal/GET_DATA', this.$route.params.id)
        .then(response => {
          this.form = response.data
          this.phone_value = response.data.phone ?? ''
          if (response.data.address_json) {
            this.address_detail = response.data.address_json
          } else if (response.data.address) {
            this.address_detail = { address: response.data.address }
          }
          this.$store.dispatch('localOrg/GET_INREG_QUERY', { query: '', region_org: this.form.region_org })
            .then(response => { this.local_options = response.data })
        })
        .catch(error => {
          this.loading = false
          for (const key in error.response.data) {
            if (typeof error.response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                title: 'Внимание!',
                text: error.response.data[key]
              })
            } else {
              this.$notify({
                type: 'error',
                title: 'Внимание!',
                text: error.response.data[key][0]
              })
            }
          }
        })
    },
    loadAwards() {
      // Загрузка данных из fixtures/awardsmember.json
      const awardsData = [
        {
          "model": "common.awardtype",
          "pk": "ro",
          "fields": {
            "name": "Награды РО РСМ",
            "sort": 10
          }
        },
        {
          "model": "common.awardtype",
          "pk": "rsm",
          "fields": {
            "name": "Награды РСМ",
            "sort": 20
          }
        },
        {
          "model": "common.award",
          "pk": 1,
          "fields": {
            "name": "Благодарность Председателя РО РСМ",
            "type": "ro",
            "sort": 10
          }
        },
        {
          "model": "common.award",
          "pk": 2,
          "fields": {
            "name": "Благодарность Председателя РСМ",
            "type": "rsm",
            "sort": 10
          }
        },
        {
          "model": "common.award",
          "pk": 3,
          "fields": {
            "name": "Почетный диплом РСМ",
            "type": "rsm",
            "sort": 20
          }
        },
        {
          "model": "common.award",
          "pk": 4,
          "fields": {
            "name": "Знак «За активную работу в Российском Союзе Молодежи»",
            "type": "rsm",
            "sort": 30
          }
        },
        {
          "model": "common.award",
          "pk": 5,
          "fields": {
            "name": "Знак «За заслуги» II степени",
            "type": "rsm",
            "sort": 40
          }
        },
        {
          "model": "common.award",
          "pk": 6,
          "fields": {
            "name": "Знак «За заслуги» I степени",
            "type": "rsm",
            "sort": 50
          }
        },
        {
          "model": "common.award",
          "pk": 7,
          "fields": {
            "name": "Знак «За поддержку деятельности Российского Союза Молодежи» (партнерский знак РСМ)",
            "type": "rsm",
            "sort": 60
          }
        },
        {
          "model": "common.award",
          "pk": 8,
          "fields": {
            "name": "Медаль РСМ «За вклад в работу с молодежью»",
            "type": "rsm",
            "sort": 70
          }
        },
        {
          "model": "common.award",
          "pk": 9,
          "fields": {
            "name": "Почетное звание «Почетный член РСМ»",
            "type": "rsm",
            "sort": 80
          }
        }
      ]
      // Разделяем данные на типы и награды
      this.awardTypes = {}
      this.awards = []
      awardsData.forEach(item => {
        if (item.model === 'common.awardtype') {
          this.awardTypes[item.pk] = item.fields
        } else if (item.model === 'common.award') {
          this.awards.push({
            id: item.pk,
            name: item.fields.name,
            type: item.fields.type,
            sort: item.fields.sort
          })
        }
      })
      // Сортируем награды по полю sort
      this.awards.sort((a, b) => a.sort - b.sort)
    },
    addSelectedAward() {
      if (this.selectedAward) {
        const existingAward = this.form.awards.find(award => award.id === this.selectedAward.id)
        if (!existingAward) {
          this.form.awards.push(this.selectedAward)
        }
        this.selectedAward = null
      }
    },
    removeAward(index) {
      this.form.awards.splice(index, 1)
    },
    getAwardLabel(option) {
      return `${option.name} (${this.awardTypes[option.type]?.name || 'Неизвестный тип'})`
    },
    onCheckForm() {
      this.onCheckBirth()
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        });
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      this.$store.dispatch('journal/PATCH_DATA', { id: this.$route.params.id, data: this.form })
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Данные сохранены.'
          });
          this.$router.push({ name: 'journal-list' })
        })
        .catch(error => {
          this.loading = false
          for (const key in error.response.data) {
            if (typeof error.response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                title: 'Внимание!',
                text: error.response.data[key]
              })
            } else {
              this.$notify({
                type: 'error',
                title: 'Внимание!',
                text: error.response.data[key][0]
              })
            }
          }
        })
    },
    sendNote() {
      this.$store.dispatch('journal/PATCH_DATA', { id: this.$route.params.id, data: { note: this.form.note } })
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Примечание изменено.'
          });
        })
        .catch(() => {
          this.loading = false
          this.$notify({
            type: 'error',
            title: 'Ошибка!',
            text: 'Ошибка изменения примечания.'
          })
        })
    },
    onCheckBirth() {
      this.$v.form.dob.$touch()
      const today = new Date()
      const formatBirth = this.form.dob.slice(3, 6) + this.form.dob.slice(0, 3) + this.form.dob.slice(6)
      const birthDate = new Date(formatBirth)
      this.age = today.getFullYear() - birthDate.getFullYear()
      const month = today.getMonth() - birthDate.getMonth()
      if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
        this.age--
      }
      this.is_adult = this.age > 17
      if (this.age < 14) {
        this.is_adult = true
        this.$v.age.$touch()
      }
    },
    onAddressSearch(search, loading) {
      loading(true);
      this.searchAddress(loading, search, this);
    },
    searchAddress: debounce((loading, search, vm) => {
      vm.$store.dispatch('FIND_ADDRESS', { search }).then(response => {
        vm.address_options = response.data;
        loading(false);
      });
    }, 350),
    setValues() {
      if (this.address_detail) {
        this.form.address_json = this.address_detail
        this.form.address = this.address_detail.address
      } else {
        this.form.address_json = ''
        this.form.address = ''
      }
    },
    getLabel(option) {
      if (typeof option === 'object') {
        if (Object.prototype.hasOwnProperty.call(!option, this.label)) {
          return console.warn(
            `[vue-select warn]: Label key "option.${this.label}" does not` +
            ` exist in options object ${JSON.stringify(option)}.
` +
            'https://vue-select.org/api/props.html#getoptionlabel'
          );
        }
        return option[this.label];
      }
      return option;
    },
    openSidebar() {
      this.show_sidebar = true
    },
    onContinue() {
      this.$router.push({ name: 'journal-continue-create', query: { id: this.$route.params.id } })
    },
    onRefuse() {
      this.$router.push({ name: 'journal-refuse-create', query: { id: this.$route.params.id } })
    }
  }
}
</script>
<style scoped>
/* Стили для виджета статуса */
.status-widget {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  margin-bottom: 24px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
}

.status-widget--active {
  background-color: #4caf50; /* Зеленый для активного статуса */
}

.status-widget--warning {
  background-color: #ff9800; /* Оранжевый для статуса "требует внимания" */
}

.status-widget--disactive {
  background-color: #f44336; /* Красный для статуса "снят с учета" */
}
</style>